.footer {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .footer-links-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .footer-link {
      color: $white;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
        color: $primary;
      }
    }
  }
}

@media (max-width: 768px) {
  .footer {
    max-width: 540px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 18px;
    font-size: 18px;
    padding: 30px 14px;
    margin-top: 10px;

    h4 {
      font-size: 14px;
    }

    .footer-links-container {
      gap: 18px;
    }
  }
}
@media (min-width: 768px) {
  .footer {
    max-width: 720px;
    font-size: 20px;
    padding: 50px 15px;
    margin-top: 30px;

    h4 {
      font-size: inherit;
    }

    .footer-links-container {
      gap: 20px;
    }
  }
}
@media (min-width: 992px) {
  .footer {
    max-width: 960px;
    font-size: 16px;

    .footer-links-container {
      font-size: 20px;
      gap: 24px;
    }
  }
}

@media (min-width: 1200px) {
  .footer {
    max-width: 1400px;
    font-size: 20px;

    .footer-links-container {
      font-size: 24px;
      gap: 28px;
    }
  }
}
