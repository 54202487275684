.horizontal-bar-chart {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  overflow: hidden;

  .entry {
    width: 0px;
    height: 100%;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    gap: 2px;
    overflow: hidden;

    .label {
      font-size: 16px;
      font-weight: 700;
      color: $white;
    }

    .value {
      font-size: 16px;
      font-weight: 500;
      color: $white;
    }

    p {
      margin: 0;
    }
  }
}
