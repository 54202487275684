.selection-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-left: auto;
  margin-right: auto;

  .grid-container {
    display: grid;
    justify-content: space-between;
    width: 100%;
  }
}

.general-container {
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .selection-container {
    gap: 20px;
    .grid-container {
      grid-template-columns: repeat(2, 160px);
      gap: 30px;
    }
  }
}
@media (min-width: 768px) {
  .general-container {
    max-width: 500px;
  }

  .selection-container {
    max-width: 500px;
    gap: 25px;

    .grid-container {
      grid-template-columns: repeat(4, 160px);
      gap: 0px;
    }
  }
}
@media (min-width: 992px) {
  .general-container {
    max-width: 600px;
  }

  .selection-container {
    max-width: 600px;
  }
}

@media (min-width: 1200px) {
  .general-container {
    max-width: 800px;
  }

  .selection-container {
    max-width: 800px;
    gap: 30px;
  }
}
