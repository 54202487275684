@keyframes slide-description {
  0% {
    min-height: 0px;
  }
  100% {
    min-height: 100px;
  }
}

.table {
  width: 100%;
  margin: 0 auto;
  > tbody > tr > td {
    color: $white;
    white-space: nowrap;
    font-size: 16px;

    .photo {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }
  }

  > tbody > tr.table-success > td {
    background-color: darken($success, 10%);
  }

  > tbody > tr.table-info > td {
    background-color: $info;
  }

  > tbody > tr.table-primary > td {
    background-color: $primary;
  }

  > tbody > tr.table-warning > td {
    background-color: $warning;
  }
  > tbody > tr.table-danger > td {
    background-color: $danger;
  }

  .form-check {
    margin: 0;
    margin-top: 5px;

    & label .form-check-sign::before,
    & label .form-check-sign::after {
      top: -17px;
      left: 4px;
    }
  }

  .btn {
    margin: 0;
  }

  small,
  .small {
    font-weight: 300;
  }

  .card-tasks .card-body & {
    margin-bottom: 0;

    > thead > tr > th,
    > tbody > tr > th,
    > tfoot > tr > th,
    > thead > tr > td,
    > tbody > tr > td,
    > tfoot > tr > td {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  > thead > tr > th {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: $font-weight-extra-bold;
    border: solid 1px rgba($white, 0.3);
    border-left-width: 0px;
    border-top-width: 0px;
    color: $primary;
    white-space: nowrap;
  }

  thead > tr > th:last-child {
    border-right-width: 0px;
  }

  .radio,
  .checkbox {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    width: 15px;

    .icons {
      position: relative;
    }

    label {
      &:after,
      &:before {
        top: -17px;
        left: -3px;
      }
    }
  }
  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    border: solid 1px rgba($white, 0.3);
    border-left-width: 0px;
    border-top-width: 0px;
    padding: 10px;
    vertical-align: middle;
  }

  tbody tr:last-child td,
  tbody .force-last td {
    border-bottom: none; // Remove bottom border on the children of the last row
  }

  tbody td:last-child {
    border-right: none; // Remove right border of the children of the last column
  }

  .th-description {
    max-width: 150px;
  }
  .td-price {
    font-size: 26px;
    font-weight: $font-weight-light;
    margin-top: 5px;
    position: relative;
    top: 4px;
    text-align: right;
  }
  .td-total {
    font-weight: $font-weight-bold;
    font-size: $h5-font-size;
    padding-top: 20px;
    text-align: right;
  }

  .td-actions-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .td-actions {
    .td-toggler {
      background-color: transparent;
      border: none;
      outline: none !important;
      color: rgba($white, 0.8);
      transform: rotate(0deg);
      transition: all 0.25s ease-in;

      &.active {
        transform: rotate(180deg);
      }
    }
  }

  > tbody > tr {
    position: relative;
  }

  > tfoot > tr {
    color: hsla(0, 0%, 100%, 0.7);
    text-transform: uppercase;
  }
}

.table-responsive {
  overflow: scroll;
  padding-bottom: 10px;
}

#tables .table-responsive {
  margin-bottom: 30px;
}

// datatables

.dataTables_wrapper {
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .form-control-sm {
    font-size: 10px;
  }
}

.tablehover {
  > tbody > tr:hover {
    background-color: $blue;
  }
}
