.custom-link {
  color: white;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease-in-out;

  &:hover {
    text-decoration: $primary;
  }
}
