.form-control:focus,
.form-control:focus + .input-group-append .input-group-text,
.form-control:focus ~ .input-group-append .input-group-text,
.form-control:focus + .input-group-prepend .input-group-text,
.form-control:focus ~ .input-group-prepend .input-group-text,
.input-group-focus .input-group-prepend .input-group-text,
.input-group-focus .input-group-append .input-group-text {
  border-color: $info;
}
.has-danger .form-control:focus {
  border-color: lighten($danger-states, 5%);
}
