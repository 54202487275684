.custom-input .form-control::placeholder {
  font-size: 30px;
  font-weight: 700;
  color: #ffffff77;
}

.custom-input .form-control {
  font-size: 30px;
  height: fit-content;
  border-radius: 10px;
  border: solid 2px #fd5bb033;
  color: white;
  font-weight: 700;
  background-color: #00000044;
}

.custom-input .form-control:focus {
  border-color: $primary;

  & + .input-group-append .input-group-text {
    border: solid 2px $primary;
    border-left: none;

    background-color: #00000044;
  }
}

.custom-input .input-group-text {
  border: solid 2px #fd5bb033;
  border-left: none;
  background-color: #00000044;
}

.custom-input.is-valid {
  .form-control {
    border-color: $primary;
  }

  .input-group-text {
    border-color: $primary;
  }
}

.custom-input.is-invalid {
  .form-control {
    border-color: #dd2f2f;
    color: #dd2f2f;
  }

  .input-group-text {
    border-color: #dd2f2f;
  }
}

.custom-input.is-invalid .form-control:focus {
  border-color: #dd2f2f;

  & + .input-group-append .input-group-text {
    border-color: #dd2f2f;
  }
}

.custom-input .input-group-text {
  font-size: 22px;
  font-weight: 700;
  color: #ffffff;
}

.custom-input.small-input {
  .form-control::placeholder {
    font-size: 20px;
  }

  .form-control {
    font-size: 20px;
  }

  .input-group-text {
    font-size: 18px;
  }
}
