.stats-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 48px;
  padding-left: 48px;
  padding-right: 48px;
  color: white;
  font-size: 16px;
  gap: 20px;
}

.stats-address {
  width: 45%;
}

.category-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 12px;
  gap: 40px;
}

@media (max-width: 768px) {
  .stats-address {
    width: 100%;
  }

  .stats-section {
    padding-top: 12px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
  }

  .category-container {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
}
