.pagination-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;

  button {
    width: 40px;
    height: 40px;
    font-size: 24px;
    color: $white;
    font-weight: 600;
    background: transparent;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease;
    border: none;
    margin: 0;
    padding: 0;

    * {
      margin: 0;
      padding: 0;
    }

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  .pagination-blocker {
    width: 40px;
    height: 40px;
  }

  p {
    font-size: 32px;
    color: $primary;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
}
