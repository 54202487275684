#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  // make direct children take width of parent
  > * {
    width: 100%;
  }
}
