.progress-bar-container {
  width: 100%;
  padding-top: 35px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 20px;

  .current-day {
    position: absolute;
    width: 100px;
    top: 0;
    left: 0;
    transition: all 0.5s ease;
    font-size: 18px;
    font-weight: 700;
    color: $primary;
  }

  .progress-bar {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 93%;
    height: 20px;
    background-color: #00000066;
    border-radius: 10px;
    .track {
      position: absolute;
      width: 0;
      height: 100%;
      background-color: $primary;
      border-radius: 10px;
      transition: all 0.5s ease;
    }
  }
  .label-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .label {
      font-size: 16px;
      font-weight: 600;
      color: $white;
    }
  }
}
