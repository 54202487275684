.landing {
  margin-right: auto;
  margin-left: auto;
  animation: opacity-anim 2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  h2 {
    font-weight: 900;
    color: #00000044;
    -webkit-text-stroke-color: $primary;
    margin: 0;
    text-align: center;
  }

  .main-banner {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      height: auto;
    }

    h1 {
      font-weight: 900;
      color: #00000044;
      -webkit-text-stroke-color: $primary;
      margin: 0;
      text-align: center;
    }

    h3 {
      font-weight: 600;
      color: white;
      margin: 0;
      text-align: center;
    }
  }

  .advantages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      h4 {
        border: solid 2px $primary;
        font-weight: 600;
        background-color: #00000044;
        text-align: center;
        margin: 0;
        color: white;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    a {
      background-color: $primary;
      border: none;
      color: white;
      font-weight: 700;
      transition: all 0.5s ease;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        box-shadow: 0 0 30px 0 #fd5bb066;
      }
    }
  }

  .ecosystem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .tokens-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .single-token {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        * {
          margin: 0;
        }

        h3 {
          font-weight: 700;
          color: $primary;
        }

        p {
          color: white;
          text-align: center;
          word-break: keep-all;
        }
      }
    }
  }

  .how-it-works {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .phases-bar-mobile {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .chevron-div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        * {
          cursor: pointer;
        }
      }

      h4 {
        color: $primary;
        margin: 0;
        font-weight: 600;
      }
    }

    .phases-bar {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;

      .phase {
        flex: 0;
        padding-left: 6%;
        padding-right: 6%;
        transition: all 0.5s ease;
        text-align: center;
        cursor: pointer;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        p {
          width: fit-content;
          padding-left: 4%;
          padding-right: 4%;
          font-weight: 600;
          color: white;
          border-bottom: 2px solid transparent;
          transition: all 0.5s ease;
        }

        &.active {
          flex: 1;

          p {
            color: $primary;
            border-bottom: 2px solid $primary;
          }
        }

        &:hover {
          p {
            color: $primary;
          }
        }
      }
    }

    .phase-container {
      border: solid 2px $primary;
      background-color: #00000044;
      width: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .phase-description {
        color: white;
        text-align: center;
        white-space: pre-line;
        width: 95%;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          display: block;

          margin-top: auto;
          margin-bottom: auto;
          font-weight: 600;
        }

        strong {
          color: $primary;
          font-weight: 600;
        }
      }

      .phase-actions-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        margin-bottom: 0;
        width: 100%;

        a {
          background-color: $primary;
          border: none;
          color: white;
          font-weight: 700;
          transition: all 0.5s ease;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            box-shadow: 0 0 30px 0 #fd5bb066;
          }
        }
      }
    }
  }

  .security {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    p {
      color: white;
      text-align: center;
    }

    .security-reasons {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      h4 {
        border: solid 2px $primary;
        color: white;
        font-weight: 600;
        background-color: #00000044;
        text-align: center;
        margin: 0;
      }
    }

    a {
      font-weight: 600;
      color: white;
      transition: all 0.5s ease;

      &:hover {
        color: $primary;
      }
    }
  }
}

@media (max-width: 768px) {
  .landing {
    padding: 12px 14px;
    max-width: 540px;
    gap: 40px;

    h2 {
      font-size: 36px;
      -webkit-text-stroke-width: 1.3px;
      margin-bottom: 12px;
    }

    h4 {
      font-size: 16px;
    }

    p {
      font-size: 13px;
    }

    .main-banner {
      gap: 10px;

      h1 {
        font-size: 80px;
        -webkit-text-stroke-width: 2px;
        margin-bottom: 8px;
      }

      h3 {
        font-size: 16px;
        word-break: keep-all;
      }
    }

    .advantages {
      gap: 20px;

      div {
        gap: 20px;
        flex-direction: column;

        h4 {
          width: 310px;
          padding: 12px 18px;
          border-radius: 14px;
        }
      }
    }

    .actions {
      gap: 20px;
      flex-direction: column;
      a {
        width: 200px;

        font-size: 18px;
        padding: 14px 0px;
        border-radius: 14px;
      }
    }

    .ecosystem {
      gap: 20px;

      .tokens-container {
        gap: 20px;

        .single-token {
          img {
            width: 100px;
            height: auto;
          }

          h3 {
            font-size: 24px;
            margin-top: 10px;
            margin-bottom: 4px;
          }
        }
      }
    }

    .how-it-works {
      .phases-bar-mobile {
        h4 {
          font-size: 20px;
        }

        .chevron-div {
          gap: 12px;
          font-size: 18px;
        }
      }

      .phases-bar {
        display: none;

        .phase {
          p {
            padding-bottom: 5px;
            font-size: 20px;
          }
        }
      }

      .phase-container {
        margin-top: 12px;
        padding: 26px 20px;
        border-radius: 14px;
        height: 475px;

        .phase-actions-container {
          gap: 20px;
          flex-direction: column;

          a {
            width: 180px;
            font-size: 16px;
            padding: 12px 0px;
            border-radius: 8px;
          }
        }
      }
    }

    .security {
      .security-reasons {
        margin-top: 15px;
        margin-bottom: 30px;
        gap: 20px;
        flex-direction: column;

        h4 {
          width: 100%;
          display: flex;
          align-items: center;
          height: 135px;
          padding: 0px 15px;
          border-radius: 14px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .landing {
    max-width: 720px;
    gap: 65px;

    h2 {
      font-size: 48px;
      -webkit-text-stroke-width: 1.6px;
      margin-bottom: 16px;
    }

    h4 {
      font-size: 18px;
    }

    p {
      font-size: 15px;
    }

    .main-banner {
      gap: 10px;

      h1 {
        font-size: 145px;
        -webkit-text-stroke-width: 3px;
        margin-bottom: 8px;
      }

      h3 {
        font-size: 20px;
      }
    }

    .advantages {
      gap: 35px;

      div {
        gap: 35px;
        flex-direction: row;

        h4 {
          width: 380px;
          padding: 14px 20px;
          border-radius: 14px;
        }
      }
    }

    .actions {
      gap: 40px;
      flex-direction: row;
      a {
        width: 230px;

        font-size: 24px;
        padding: 16px 0px;
        border-radius: 14px;
      }
    }

    .ecosystem {
      gap: 30px;

      .tokens-container {
        gap: 80px;

        .single-token {
          img {
            width: 155px;
            height: auto;
          }

          h3 {
            font-size: 28px;
            margin-top: 12px;
            margin-bottom: 5px;
          }
        }
      }
    }

    .how-it-works {
      .phases-bar-mobile {
        display: none;
      }

      .phases-bar {
        .phase {
          p {
            padding-bottom: 5px;
            font-size: 20px;
          }
        }
      }

      .phase-container {
        margin-top: 18px;
        padding: 28px 20px;
        border-radius: 14px;
        height: 400px;

        .phase-actions-container {
          gap: 22px;
          flex-direction: row;

          a {
            width: 190px;
            font-size: 18px;
            padding: 12px 0px;
            border-radius: 8px;
          }
        }
      }
    }

    .security {
      .security-reasons {
        margin-top: 18px;
        margin-bottom: 40px;
        gap: 40px;
        flex-direction: row;

        h4 {
          width: 440px;
          display: flex;
          align-items: center;
          height: 190px;
          padding: 0px 20px;
          border-radius: 14px;
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .landing {
    padding: 35px 15px;
    max-width: 960px;
    gap: 80px;

    h2 {
      font-size: 60px;
      -webkit-text-stroke-width: 2px;
      margin-bottom: 20px;
    }

    h4 {
      font-size: 20px;
    }

    p {
      font-size: 17px;
    }

    .main-banner {
      gap: 12px;

      h1 {
        font-size: 160px;
        -webkit-text-stroke-width: 4px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 22px;
      }
    }

    .advantages {
      gap: 40px;

      div {
        gap: 40px;

        h4 {
          width: 400px;
          padding: 16px 24px;
          border-radius: 16px;
        }
      }
    }

    .actions {
      gap: 50px;
      a {
        width: 250px;

        font-size: 26px;
        padding: 18px 0px;
        border-radius: 16px;
      }
    }

    .ecosystem {
      gap: 40px;

      .tokens-container {
        gap: 80px;

        .single-token {
          img {
            width: 180px;
            height: auto;
          }

          h3 {
            font-size: 30px;
            margin-top: 12px;
            margin-bottom: 6px;
          }
        }
      }
    }

    .how-it-works {
      .phases-bar {
        .phase {
          p {
            padding-bottom: 6px;
            font-size: 22px;
          }
        }
      }

      .phase-container {
        margin-top: 20px;
        padding: 30px 25px;
        border-radius: 16px;
        height: 430px;

        .phase-actions-container {
          gap: 25px;

          a {
            width: 220px;
            font-size: 20px;
            padding: 14px 0px;
            border-radius: 10px;
          }
        }
      }
    }

    .security {
      .security-reasons {
        margin-top: 20px;
        margin-bottom: 50px;
        gap: 50px;

        h4 {
          width: 420px;
          display: flex;
          align-items: center;
          height: 190px;
          padding: 0px 20px;
          border-radius: 16px;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .landing {
    max-width: 1400px;
    gap: 100px;

    h2 {
      font-size: 80px;
      -webkit-text-stroke-width: 2px;
      margin-bottom: 30px;
    }

    h4 {
      font-size: 22px;
    }

    p {
      font-size: 20px;
    }

    .main-banner {
      gap: 16px;

      h1 {
        font-size: 220px;
        -webkit-text-stroke-width: 4px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 28px;
      }
    }

    .advantages {
      gap: 40px;

      div {
        gap: 40px;

        h4 {
          width: 400px;
          padding: 20px 30px;
          border-radius: 16px;
        }
      }
    }

    .actions {
      gap: 60px;
      a {
        width: 300px;

        font-size: 32px;
        padding: 20px 0px;
        border-radius: 16px;
      }
    }

    .ecosystem {
      gap: 50px;

      .tokens-container {
        gap: 100px;

        .single-token {
          img {
            width: 250px;
            height: auto;
          }

          h3 {
            font-size: 36px;
            margin-top: 16px;
            margin-bottom: 8px;
          }
        }
      }
    }

    .how-it-works {
      .phases-bar {
        .phase {
          p {
            padding-bottom: 8px;
            font-size: 26px;
          }
        }
      }

      .phase-container {
        margin-top: 20px;
        padding: 40px 30px;
        border-radius: 16px;
        height: 490px;

        .phase-description {
          font-size: 22px;
        }

        .phase-actions-container {
          gap: 30px;

          a {
            width: 240px;
            font-size: 22px;
            padding: 16px 0px;
            border-radius: 10px;
          }
        }
      }
    }

    .security {
      .security-reasons {
        margin-top: 30px;
        margin-bottom: 60px;
        gap: 60px;

        h4 {
          width: 500px;
          display: flex;
          align-items: center;
          height: 210px;
          padding: 0px 30px;
          border-radius: 16px;
        }
      }
    }
  }
}

@keyframes opacity-anim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
