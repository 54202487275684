.loading-bar-container {
  width: 100%;
  height: 15px;
  border-radius: 10px;
  background-color: #00000044;
  position: relative;
  overflow: hidden;

  .loading-bar-pill {
    position: absolute;
    width: 5%;
    height: 100%;
    border-radius: 10px;
    background-color: #fd5bb0;
    top: 0;

    animation: loading 3s infinite;
  }

  @keyframes loading {
    0% {
      left: 0%;
    }
    50% {
      left: 95%;
    }
    100% {
      left: 0%;
    }
  }
}
