.slider-input {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  /*  slider progress trick  */
  overflow: hidden;
  border-radius: 16px;
  background: #00000000;
}

/* Track: webkit browsers */
.slider-input::-webkit-slider-runnable-track {
  height: 18px;
  background: #00000066;
  border-radius: 16px;
}

/* Track: Mozilla Firefox */
.slider-input::-moz-range-track {
  height: 18px;
  background: #00000066;
  border-radius: 16px;
}

/* Thumb: webkit */
.slider-input::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  height: 18px;
  width: 18px;
  background-color: $white;
  border-radius: 50%;
  border: 2px solid $primary;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px $primary;
}

/* Thumb: Firefox */
.slider-input::-moz-range-thumb {
  height: 18px;
  width: 18px;
  background-color: $white;
  border-radius: 50%;
  border: 1px solid $primary;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px $primary;
}

.slider-input:disabled::-webkit-slider-thumb {
  opacity: 0.5;
}

.slider-input:disabled::-moz-range-thumb {
  opacity: 0.5;
}
