.main-button {
  background-color: #fd5bb0;
  border: none;
  color: white;
  font-weight: 700;
  font-size: 24px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
  transition: all 0.5s ease;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.small-button {
    font-size: 18px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &.very-small-button {
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 6px;
    padding-right: 6px;
  }

  &:hover {
    box-shadow: 0 0 20px 0 #fd5bb066;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:disabled:hover {
    box-shadow: none;
  }
}
