.staking-stats-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .staking-stats-container {
    flex-direction: row;
    align-items: center;
    gap: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.description {
  opacity: 0;
  border-top-width: 0 !important;
  padding: 0px !important;
  transition: all 0.5s linear;

  .description-wrapper {
    height: 0px;
  }

  &.active {
    opacity: 1;
    border-top-width: 0.0625rem !important;
    padding: 30px !important;

    .description-wrapper {
      opacity: 1;
      height: 190px;
    }
  }

  .description-wrapper {
    opacity: 0;
    overflow: hidden;
    transition: all 0.5s linear;
    display: flex;
    flex-direction: row;
    gap: 40px;

    .stake-panel {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .button-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 20px;
      }
    }

    .claim-panel {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      gap: 20px;

      h3 {
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 0;
      }
    }
  }
}
