// style for the landing page
.wrapper {
  flex: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-none {
  cursor: not-allowed;
}

.modal-open {
  overflow-x: hidden;
  overflow-y: auto;
}

.landing-page {
  overflow-x: hidden;
  padding-right: 0px !important;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #000018;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0.44, rgb(82, 17, 111)),
      color-stop(0.72, rgb(42, 7, 71)),
      color-stop(0.86, rgb(45, 9, 71))
    );
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-thumb:hover {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
  }

  .page-header {
    &::after {
      background: radial-gradient(
        ellipse at top right,
        #292d61 23%,
        #171941 65%
      );
      // content: "";
      position: absolute;
      height: 100vh;
      width: 100%;
      opacity: 0.5;
    }
    .path {
      max-width: 65%;
      right: 0;
      top: 30px;
    }

    .path2 {
      position: absolute;
      opacity: 0.02;
      max-width: 41%;
      right: -60px;
      top: -50px;
    }

    .wave {
      top: 45%;
      right: 30%;
      max-height: 90px;
    }

    .circle {
      top: 20%;
      right: 36%;
      max-height: 75px;
    }

    .triangle {
      top: 35%;
      left: 5%;
      max-height: 200px;
    }
  }

  .section:first-of-type {
    .path {
      left: -110px;
      max-width: 60%;
      top: -50px;
    }
  }
  .section:nth-of-type(2) {
    .path {
      left: auto;
      right: -250px;
      max-width: 40%;
      top: 0;
    }
    .path2 {
      position: absolute;
      opacity: 0.02;
      right: auto;
      left: 420px;
      max-width: 10%;
      top: 420px;
    }

    .path3 {
      position: absolute;
      opacity: 0.02;
      left: auto;
      right: 500px;
      max-width: 10%;
      top: -90px;
    }
  }
  .section:nth-of-type(3) {
    .path {
      right: auto;
      left: 50px;
      max-width: 45%;
      top: 60px;
    }
  }
  .section:nth-of-type(4) {
    .path {
      max-width: 60%;
      right: 3%;
      top: 15%;
    }
    .path2 {
      position: absolute;
      opacity: 0.02;
      left: 25%;
      right: auto;
      max-width: 10%;
      top: 100px;
    }
  }
  .section:nth-of-type(5) {
    .path {
      max-width: 50%;
      left: 5%;
      top: 3%;
    }
  }

  .header {
    height: 100vh;
    position: relative;

    .container {
      padding-top: 26vh;
      color: $white;
      z-index: 2;
      position: relative;
    }

    .share {
      margin-top: 150px;
    }
    h1 {
      font-weight: 600;
    }
    .title {
      color: $white;
    }
  }

  .section-team {
    .team .team-player img {
      max-width: 100px;
    }

    .team-player {
      margin-bottom: 15px;
    }
  }

  .section-contact-us {
    .title {
      margin-bottom: 15px;
    }

    .description {
      margin-bottom: 30px;
    }

    .input-group,
    .send-button,
    .textarea-container {
      padding: 0 40px;
    }

    .textarea-container {
      margin: 40px 0;
    }

    a.btn {
      margin-top: 35px;
    }
  }

  .section-safe {
    .icon {
      i {
        font-size: 21px;
      }
    }
  }
}

.section-full-page {
  &:after,
  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &:before {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &[data-image]:after {
    opacity: 0.5;
  }

  > .content,
  > .footer {
    position: relative;
    z-index: 4;
  }

  > .content {
    min-height: calc(100vh - 80px);
  }

  .full-page-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }

  .footer nav > ul a:not(.btn),
  .footer,
  .footer .copyright a {
    color: $white;
  }
}

.landing-page {
  background-image: url(#{$asset-base-path} + "/img/dots.png"),
    radial-gradient(circle 950px at top center, #2d0947, #000018);
  background-size: contain, cover;
}

@media (max-width: 768px) {
  .landing-page {
    background-image: url(#{$asset-base-path} + "/img/dots.png"),
      radial-gradient(circle 500px at top center, #2d0947, #000018);
  }
}

@media (min-width: 768px) {
  .landing-page {
    background-image: url(#{$asset-base-path} + "/img/dots.png"),
      radial-gradient(circle 650px at top center, #2d0947, #000018);
  }
}

@media (min-width: 992px) {
  .landing-page {
    background-image: url(#{$asset-base-path} + "/img/dots.png"),
      radial-gradient(circle 800px at top center, #2d0947, #000018);
  }
}

@media (min-width: 1200px) {
  .landing-page {
    background-image: url(#{$asset-base-path} + "/img/dots.png"),
      radial-gradient(circle 950px at top center, #2d0947, #000018);
  }
}

@media (min-width: 1500px) {
  .landing-page {
    background-image: url(#{$asset-base-path} + "/img/dots.png"),
      radial-gradient(circle 1300px at top center, #2d0947, #000018);
  }
}

@include media-breakpoint-down(md) {
  .section:nth-of-type(2) {
    .path {
      left: 10% !important;
    }
    .path2 {
      left: 20% !important;
    }
  }

  .info:first-of-type {
    padding-top: 10% !important;
  }
}

@include media-breakpoint-down(sm) {
  .card-login img {
    width: 70%;
  }

  .section:nth-of-type(2) {
    .path,
    .path2 {
      left: 0 !important;
    }
  }
}

@include media-breakpoint-down(xs) {
  .card-login {
    margin-top: 100px;
    img {
      width: 350px;
    }
  }

  .landing-page {
    .page-header {
      h1 {
        margin-top: 100px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .info:first-of-type {
    padding-top: 60%;
  }
}

.w-full {
  width: 100%;
}

.no-overflow {
  overflow: hidden;
}

.walletInfo {
  display: flex;
  align-items: center;
  // cursor: pointer;

  > img {
    margin-right: 0.7em;
  }

  .connectWalletText {
    -webkit-font-smoothing: antialiased;
    font-weight: 600;
    font-size: 1.1em;
    letter-spacing: 0.02em;
    line-height: 1;
    color: #333;
    > span {
      color: #96908c;
      font-size: 0.8em;
      display: block;
      margin-top: 0.5em;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1400px;
  }
}

.filter-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgba(255, 255, 255, 0.8);

  .filter-component {
    padding: 0 15px;

    span {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.overflow-y {
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgb(81, 83, 120, 0.5);
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background-image: -webkit-gradient(
      linear,
      right bottom,
      left bottom,
      color-stop(0.86, #1c3a94),
      color-stop(0.5, #497dbd),
      color-stop(0.86, #1c3a94)
    );
  }

  &::-webkit-scrollbar-thumb:hover {
    -webkit-box-shadow: inset 0 0 3px rgba(26, 26, 162, 0.5);
  }
}

.w-btn {
  width: 100px;
}

.scroll-to-top {
  position: relative;
  animation: showIn 0.5s;

  .top {
    background-color: rgb(131, 123, 191, 0.5) !important;
    right: 40px;
    bottom: 40px;
    position: fixed;
    z-index: 2;
    cursor: pointer;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    box-shadow: 0 9px 25px 0 rgba(132, 128, 177, 0.28);
    border: none;
    animation: flowbtn 3s ease-in-out infinite;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .top:hover,
  .top:visited,
  .top:focus {
    animation: none;
    border: none;
  }
}

@keyframes flowbtn {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes showIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
