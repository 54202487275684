img{
    max-width: 100%;
    // border-radius: $border-radius-sm;
}
.img-raised{
    box-shadow: $box-shadow-raised;
}

.img-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
