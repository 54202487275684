.navbar {
  width: $full-width;
  z-index: 99;
  background: $transparent-bg !important;
  padding: 35px 15px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;

  .container {
    padding: 0;
    max-width: 100%;
  }

  .navbar-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .nav-item {
      &.wallet {
        display: flex;
        align-items: center;
        border-radius: 3em;
        border: 1px solid gray;
        padding: 0px 10px;
        margin: 0px 6px;
        cursor: pointer;
        max-width: 150px;
      }

      @media screen and (max-width: 991px) {
        &.wallet {
          padding: 6px 16px;
          margin: 8px 8px 8px 0px;
        }
      }

      .nav-link {
        position: relative;
        color: inherit;
        line-height: 20px;
        display: inline-flex;
        &:hover {
          color: $opacity-8;
        }
      }
    }
  }

  .btn {
    margin: 0 5px 0 10px;
  }
  p {
    margin: 0;
  }

  &.bg-white {
    .navbar-nav {
      .search-bar.input-group {
        i {
          color: $black;
        }
      }

      .search-bar.input-group .form-control {
        background: rgba($black, 0.1);
        border-radius: 4px;
      }

      a.nav-link {
        color: $black !important;
        p {
          color: $black;
        }
      }
    }

    .navbar-text,
    .navbar-brand {
      color: $black;
    }

    .form-control {
      color: $black !important;
    }

    .form-control::placeholder {
      color: $dark-gray !important;
    }
  }

  &.bg-dark {
    background: $black !important;
  }

  &.bg-primary {
    background-color: $primary !important;
  }

  &.bg-warning {
    background-color: $warning !important;
  }

  &.bg-info {
    background-color: $info !important;
  }

  &.bg-success {
    background-color: darken($success, 10%) !important;
  }

  &.bg-danger {
    background-color: $danger !important;
  }

  .navbar-brand {
    position: relative;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    color: $white;
    text-transform: capitalize;
    font-size: $font-paragraph;
    span {
      font-weight: $font-weight-bold;
    }
    img {
      border-radius: 0;
    }
  }

  .navbar-toggle button:focus,
  .navbar-toggler {
    outline: none;
  }
}

.notification {
  background: $danger;
  color: $white;
  border-radius: $border-radius-xl;
  height: 6px;
  width: 6px;
  position: absolute;
  text-align: center;
  font-size: 12px;
  font-weight: 800;
  top: 10px;
  right: 10px;
  border: 1px solid $danger;
}

.navbar-nav li {
  a,
  button {
    color: $white;
    font-weight: 600;
    background: transparent;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease;
    border: 2px solid transparent;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    padding-left: 5px;
    padding-right: 5px;
  }

  a:hover,
  button:hover {
    color: $primary;
    text-decoration: none;
    border-bottom: 2px solid $primary;
  }

  .nav-item-selected {
    color: $primary;
    border-bottom: 2px solid $primary;
  }
}

.navbar-collapse-header {
  display: none;
}

@media screen and (max-width: 576px) {
  .navbar {
    padding: 30px 15px;
  }

  .navbar[class*="navbar-toggleable-"] .container {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 991px) {
  .navbar {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
    }

    .navbar-collapse {
      position: relative;
      width: calc(100% - 1.4rem);
      height: auto !important;
      background: transparent;

      .input-group {
        margin: 0;
        margin-top: 5px;
      }

      &.collapsing,
      &.collapse {
        animation: show_navbar_collapse 0.2s ease forwards;
      }

      &.collapsing-out {
        animation: hide_navbar_collapse 0.2s ease forwards;
      }

      .navbar-collapse-header {
        display: block;
        padding-bottom: 1rem;

        .collapse-brand {
          font-size: $font-size-xl;
          color: $white;
          a {
            font-weight: $font-weight-bold;
          }
        }

        .collapse-close {
          .navbar-toggler {
            color: $white;
            font-size: $font-size-xl;
          }
        }
      }

      .navbar-nav .d-lg-block {
        display: block !important;
      }

      .navbar-nav li {
        padding: 2px 0px;
      }
    }

    .navbar-translate {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between !important;
    }

    .navbar-nav {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding-top: 6px;
      padding-bottom: 18px;
      gap: 10px;

      li a,
      li button {
        font-size: 15px;
        transition: none;
      }
    }

    .dropdown.show .dropdown-menu {
      display: block;
    }

    .dropdown .dropdown-menu {
      display: none;
      li a {
        color: $black;
      }
    }

    .dropdown.show .dropdown-menu,
    .dropdown .dropdown-menu {
      background-color: transparent;
      border: 0;
      transition: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      width: auto;
      margin: 0px 0.7rem;
      margin-top: 0px;

      &:before {
        display: none;
      }
    }

    .dropdown-menu .dropdown-item:focus,
    .dropdown-menu .dropdown-item:hover {
      color: $white;
    }

    &.bg-white .dropdown-menu .dropdown-item:focus,
    &.bg-white .dropdown-menu .dropdown-item:hover {
      color: $default;
    }

    button.navbar-toggler[data-target="#navigation"] {
      padding-top: 0;
    }

    .navbar-toggler-bar {
      display: block;
      position: relative;
      width: 22px;
      height: 1px;
      border-radius: 1px;
      background: $white;

      &.navbar-kebab {
        height: 4px;
        width: 4px;
        margin-bottom: 3px;
        border-radius: 50%;
      }

      & + .navbar-toggler-bar {
        margin-top: 7px;
      }

      & + .navbar-toggler-bar.navbar-kebab {
        margin-top: 0px;
      }

      &.bar2 {
        width: 17px;
        transition: width 0.2s linear;
      }
    }

    &.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
      background-color: $default;
    }

    .navbar-brand {
      margin-left: 10px;
      position: relative;
    }
  }

  .navbar-nav {
    .nav-link {
      i.fa,
      i.tim-icons {
        opacity: 0.5;
      }
    }
  }

  @include topbar-x-rotation();
  @include topbar-back-rotation();
  @include bottombar-x-rotation();
  @include bottombar-back-rotation();

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@media screen and (min-width: 992px) {
  .navbar-collapse {
    background: none !important;
  }

  .navbar .navbar-toggle {
    display: none;
  }

  .navbar .navbar-nav {
    gap: 12px;

    .nav-link {
      &.profile-photo {
        padding: 0;
        margin: 7px $padding-base-horizontal;
      }
    }
  }

  .navbar {
    .caret {
      position: absolute;
      left: 80%;
      top: 55%;
      margin-left: 0;
    }
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

.navigation-example {
  .navbar {
    z-index: 2;
  }
}

@keyframes show_navbar_collapse {
  0% {
    opacity: 0;
    transform: scale(0.95);
    transform-origin: 100% 0;
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hide_navbar_collapse {
  from {
    opacity: 1;
    transform: scale(1);
    transform-origin: 100% 0;
  }

  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

@media (max-width: 768px) {
  .navbar {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .navbar {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .navbar {
    max-width: 960px;
  }

  .navbar-nav li {
    a,
    button {
      font-size: 13px;
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}

@media (min-width: 1200px) {
  .navbar {
    .navbar-nav {
      gap: 32px;
    }

    max-width: 1400px;
  }

  .navbar-nav li {
    a,
    button {
      font-size: 16px;
    }
  }
}
