.select-sacrifice-token {
  width: 160px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: #00000044;
  border: solid 2px #fd5bb033;

  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;

  .token-image {
    width: auto;
    height: 70px;
    margin-bottom: 20px;
  }

  .token-name {
    color: $primary;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .token-multi {
    color: $white;
    font-size: 14px;
    font-weight: 600;
  }

  * {
    margin: 0;
  }

  &:hover,
  &.selected {
    background-color: #00000077;
    border: solid 2px $primary;
  }
}
